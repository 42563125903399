<script>
import Layout from "../../../layouts/main.vue";
import PageHeader from "@/components/page-header.vue";
import Axios from "@/plugins/axios";
import UserTableCard from "@/components/dashboard/user/user-table-card.vue";
import DataLoader from "@/components/data-loader.vue";
import UserFormModal from "@/components/dashboard/user/user-form-modal.vue";
import Pagination from "@/components/dashboard/Pagination.vue";
import {inject} from "vue";
import {COORDINATOR_} from "@/helpers/constant/roles";

export default {
  page: {
    title: "Commerciaux",
    meta: [{ name: "description", content: 'User Management' }],
  },
  components: {Pagination, UserFormModal, DataLoader, UserTableCard, Layout, PageHeader},
  data() {
    return {
      users: [],
      showDataLoader: true,
      userFormModal: false,
      activeUser: null,
      next: null,
      previous: null,
      query: '',
      page: 1,
      totalItems: 0,
      url: ''
    }
  },
  mounted() {
    this.initUrl()
    this.getUsers()
  },
  setup(){
    const globals = inject("globals");

    return {globals};
  },
  methods: {
    COORDINATOR_() {
      return COORDINATOR_
    },
    openAddUserModal(){
      this.userFormModal = true
    },
    openUpdateUserModal(user){
      this.activeUser = user
      this.userFormModal = true
    },
    closeModal(type){
      switch (type) {
        case 'user-form':
          this.activeUser = null
          this.userFormModal = false
          break
        case 'user-update-form':
          this.activeUser = null
          this.userFormModal = false
          break
      }
    },
    updateUserTable(user){
      this.users = this.users.filter(u => u.id !== user.id)
      this.users.unshift(user)
    },
    getUsers(){
      Axios.get(this.url)
          .then(response => {
            this.setPagination(response)
            response.data["hydra:member"].map(user => {
              this.users.push(user)
            })

            this.showDataLoader = false
          })
          .catch(() => {
          })
    },
    setPagination(response){
      if (response.data["hydra:view"]["hydra:next"]) {
        this.totalItems = response.data["hydra:totalItems"]
        this.next = response.data["hydra:view"]["hydra:next"]
        this.previous = response.data["hydra:view"]["hydra:previous"]
      }
    },
    initUrl(){
      this.url = '/coordinator/' + this.globals.userId + '/get/by_roles?role=commercial&page=' + this.page + '&query=' + this.query
    },
    nextPage(){
      this.setCurrentPage(this.next)
      this.users = []
      this.getUsers()
    },
    previousPage(){
      this.setCurrentPage(this.previous)
      this.users = []
      this.getUsers()
    },
    navigate(page){
      this.page = page
      this.users = []
      this.showDataLoader = true
      this.initUrl()
      this.getUsers()
    },
    setCurrentPage(url){
      const currentPage = Number(url.split('page=')[1])
      if (currentPage !== this.page) this.page = currentPage
      this.url = url.split('api/')[1]
      this.showDataLoader = true
    },
    searchUser(query){
      this.query = query
      this.showDataLoader = true
      this.users = []
      this.initUrl()
      this.getUsers()
    },
  }
};
</script>

<template>
  <Layout>
    <PageHeader
        :title="'Commerciaux'"
        :buttonTitle="'+ Commercial'"
        @submitSearch="searchUser"
        @clickButton="openAddUserModal"
    />
    <!--    <Notification/>-->
    <UserFormModal
        @onModalClose="closeModal"
        @userCreated="updateUserTable"
        :activeUser="activeUser"
        v-model="userFormModal"
        :title="'Créer un Commercial'"
        :userRole="'commercial'"
    />
    <DataLoader v-if="showDataLoader"/>
    <section>
      <div class="card" v-if="users.length > 0 && !showDataLoader">
        <div class="card-body">
          <UserTableCard
              :users="users"
              :pageName="'commercials'"
              @openUpdateUserModal="openUpdateUserModal"
              page-name="commercial"
              :role="COORDINATOR_()"
          />
          <Pagination
              :next="next"
              :previous="previous"
              :currentPage="page"
              :totalItems="totalItems"
              @navigate="navigate"
              @nextPage="nextPage"
              @previousPage="previousPage"
          />
        </div>
      </div>
    </section>
  </Layout>
</template>